
<template>
  <div class="card-container">
    <div class="card-mobile card" v-for="card in cards" @click="onCardClick(card)" :class="{'active__card': card.id === activeCard.id}">
      <div class="card-mobile__container" :class="{'active__container': card.id === activeCard.id}">
        <div class="card_item" :class="{ [cardBank(card)]: true, [cardType(card)]: true}">
          <div class="card_item_info">
            <h3 class="card_item_info__name">
              {{ transliterateToRussian(card) }}
            </h3>
            <div class="card_item_info__image" :class="{'active__checked': card.id === activeCard.id}" v-if="card.id === activeCard.id">
              <div style="height: 19px" >
                <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.6699 2L5.4086 10L1.66992 5.16475" stroke="#1490E9" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>
          </div>
          <p class="card_item__number">
            {{ card.card_mask}}
          </p>
        </div>
      </div>
    </div>
    <div class="empty-card card" @click="onCardClick({ id: 0 })"  :class="{'active__card': 0 === activeCard.id}">
      <div class="empty-card__container" :class="{'active__container': 0 === activeCard.id}">
        <img class="empty-card__container__image" src="@/assets/images/card-add.svg" alt="card-add" />
        <p>Новая карта</p>
        <div class="card_item_info__image" :class="{'active__delete': 0 === activeCard.id}" v-if="0 === activeCard.id">
          <div>
            <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.6699 2L5.4086 10L1.66992 5.16475" stroke="#1490E9" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>
<script>
import savedCardsMixin from "~/mixins/savedCardsMixin";
import {PaymentCard} from "@pp-frontend/shared-packages/types/PaymentCard";

export default {
  mixins: [savedCardsMixin],
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    cards: {
      type: Array,
      default: () => ([])
    }
  },

  data() {
    return {
      activeCard: this.value
    }
  },

  methods: {
    onCardClick (card) {
      this.activeCard = card
      this.$emit('input', Object.assign({}, card))
      this.$emit('add', card.id !== 0)
    },
    openDeletePaymentPopupComponent (cardId) {
      this.$emit('openDeletePaymentPopupComponent', cardId)
    }
  }
}
</script>

<style scoped lang="scss">
:global(.payment-card ){
  margin-top: 5px !important;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
}
.card {
  height: 120px;
  width: 47%;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.2px;
  cursor: pointer;
  position: relative;
}
.empty-card {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    background-color: #E3F1FD;
    transition: 200ms all;
    padding: 24px;
    border-radius: 8px;
    width: 100%;
    height: 100%;
  }
}
.card-mobile {
  color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &__success {
    position: absolute;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #A0AAB6;
    background-color: #fff;
    top: -10px;
    right: -7px;
  }
  &__container {
    width: 100%;
    height: 100%;
    background-color: #A0AAB6;
    transition: 200ms all;
    padding: 24px;
    border-radius: 8px;
  }
}

.active {
  &__card, &__delete, &__checked {
    border: 2.5px solid var(--primary-color) !important;
  }

  &__container {
    height: 106px;
    width: 96%;
  }
}
.card_item {
  &_info {
    &__image {
      position: absolute;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid #A0AAB6;
      background-color: #fff;
      top: -10px;
      right: -7px;
      div {
        height: 17px;
        width: 30px;
      }
    }
  }
}
</style>
