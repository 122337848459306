<template>
  <Popup @close="$emit('close')">
    <div
      class="col-md-7 mt-3 mt-md-0 popup-delete-data"
    >
      <div class="popup-delete-data_header">
        <h3 class="popup-delete-data__title">Удалить данные</h3>
        <img class="popup-delete-data__image" src="@/assets/images/popup-close.svg" alt="popup-close" @click="$emit('close')">
      </div>
      <div class="popup-delete-data_content">
        <div class="popup-delete-data_content--text">
          Вы уверены что хотите удалить эти данные?
        </div>
        <div class="popup-delete-data_content--buttons">
          <button class="popup-delete-data_content--buttons--cancel" @click="$emit('close')">Отмена</button>
          <button class="popup-delete-data_content--buttons--approve" @click="$emit('approve', card)">Удалить</button>
        </div>
      </div>
    </div>
  </Popup>
</template>
<script>
import Popup from "~/ui/Popup/Popup.vue";

export default {
  components: {Popup},
  data() {
    return {
      card: null
    }
  }
}
</script>
<style scoped lang="scss">
.popup-delete-data {
  background-color: white;
  padding: 40px;
  width: 670px;
  max-width: 670px;
  border-radius: var(--border-radius-13);
  &__image {
    cursor: pointer;
  }

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      color: var(--title-text-color);
    }

    &__image {
      width: 32px;
      height: 32px;
    }
  }
  &_content {
    margin-top: 0px;
    &--text {
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
      color: #13273E60;
    }
    &--buttons {
      margin-top: 35px;
      display: flex;
      gap: 28px;
      button {
        color: #fff;
        width: 50%;
        height: 53px;
        padding: 10px;
        border: none;
        font-size: 16px;
        font-weight: 500;
        line-height: 19.2px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &--approve {
        background-color: #FF4E55;
        border: 1px solid  #FF000080
      }
      &--cancel {
        box-shadow: 7px 7px 17.5px 0px #1490E940;
        background-color: var(--primary-color);
      }
    }
  }
}
@media (max-width: 767px) {
  .popup-delete-data {
    padding: 20px;
    width: 100%;
    max-width: 390px;
    &_header {
      &__title {
        font-size: 22px;
      }
    }
    &_content {
      margin-top: 15px;
      &--buttons {
        margin-top: 25px;
        gap: 15px;
        button {
          flex-direction: row-reverse;
        }
      }
    }
  }
}
</style>

