<template>
  <div class="loader-popup">
    <div class="loader-popup_body">
      <div class="loader-popup_body__image" >
        <svg width="102" height="100" viewBox="0 0 102 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_34_7555)">
            <circle cx="51.3567" cy="50" r="42.5" stroke="#ECF1FE" stroke-width="15"/>
            <mask id="path-2-inside-1_34_7555" fill="white">
              <path d="M9.76252 41.1589C5.72355 40.3004 3.09308 36.3001 4.54373 32.4341C8.02444 23.1579 14.2069 15.0673 22.3713 9.25878C32.579 1.99648 45.141 -1.16937 57.5714 0.38773C70.0018 1.94483 81.3948 8.11142 89.4958 17.6672C95.9751 25.3101 99.9709 34.6757 101.056 44.5237C101.508 48.6281 97.9724 51.856 93.8464 51.6917C89.7205 51.5274 86.5902 48.0155 85.8772 43.9483C84.8099 37.8613 82.1432 32.1181 78.0897 27.3367C72.4115 20.6388 64.4257 16.3164 55.7128 15.225C46.9999 14.1335 38.1947 16.3526 31.0398 21.443C25.9322 25.0768 21.9312 29.9848 19.3956 35.6205C17.7013 39.3861 13.8015 42.0174 9.76252 41.1589Z"/>
            </mask>
            <path d="M9.76252 41.1589C5.72355 40.3004 3.09308 36.3001 4.54373 32.4341C8.02444 23.1579 14.2069 15.0673 22.3713 9.25878C32.579 1.99648 45.141 -1.16937 57.5714 0.38773C70.0018 1.94483 81.3948 8.11142 89.4958 17.6672C95.9751 25.3101 99.9709 34.6757 101.056 44.5237C101.508 48.6281 97.9724 51.856 93.8464 51.6917C89.7205 51.5274 86.5902 48.0155 85.8772 43.9483C84.8099 37.8613 82.1432 32.1181 78.0897 27.3367C72.4115 20.6388 64.4257 16.3164 55.7128 15.225C46.9999 14.1335 38.1947 16.3526 31.0398 21.443C25.9322 25.0768 21.9312 29.9848 19.3956 35.6205C17.7013 39.3861 13.8015 42.0174 9.76252 41.1589Z" :stroke="color" stroke-width="30" mask="url(#path-2-inside-1_34_7555)"/>
          </g>
          <defs>
            <clipPath id="clip0_34_7555">
              <rect width="101" height="100" fill="white" transform="translate(0.548096)"/>
            </clipPath>
          </defs>
        </svg>

      </div>
      <div class="loader-popup_body_description">
        <h4 class="loader-popup_body_description__title">
           {{ text }}
        </h4>
        <p class="loader-popup_body_description__text" v-if="showText">
          Обычно это не занимает больше 30 секунд
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default  {
  props: {
    text: {
      type: String,
      default: 'Поиск задолженностей.'
    },
    showText: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: '#1490E9'
    }
  }
}
</script>

<style lang="scss" scoped>
    .loader-popup {
        display: flex;
        justify-content: center;
        align-items: center;

        &_body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;

            border-radius: 10px;
            background-color: transparent;
            padding: 30px;

            width: 280px;
            height: 256px;

            &__image {
                width: 101px;
                height: 100px;
                animation: rotate 1.5s linear infinite;

                @keyframes rotate {
                  from {
                    transform: rotate(-360deg);
                  }
                }
            }

            &_description {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 5px;

                &__title {
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 23.4px;
                    color: var(--title-text-color);
                }

                &__text {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    color: var(--bright-text-color);
                    text-align: center;
                }
            }
        }
    }
</style>
