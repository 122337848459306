<template>
  <div
    class="base-form-field"
    :class="{
      '--has-value': value || value === 0 ,
      '--error': error
    }"
  >
    <the-mask
      v-if="mask"
      :id="id"
      ref="input"
      type="text"
      :value="value"
      v-bind="attrs"
      :mask="mask"
      :tokens="maskTokens"
      :placeholder="attrs.placeholder"
      v-on="listeners"
    />
    <input
      v-else
      :id="id"
      ref="input"
      type="text"
      :value="value"
      v-bind="attrs"
      :placeholder="attrs.placeholder"
      v-on="listeners"
      class="base-form-field__input"
      :class="{ 'fns-input': fnsInput }"
    >
    <label
      :for="id"
      class="base-form-field__label"
      v-html="label"
    />
    <error-message v-if="showErrorText && error && errorMessage" :error-message="errorMessage"/>
    <div v-else-if="inputTip" class="base-form-field__text-tip" v-html="inputTip" />
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask'
import ErrorMessage from '~/components/ui/ErrorMessage.vue'
import { maskTokens } from '~/helpers/maskTokens'

export default {
  name: 'BaseInput',
  components: {
    ErrorMessage,
    TheMask
  },
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    inputTip: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      required: false,
      default: false
    },
    showErrorText: {
      type: Boolean,
      required: false,
      default: true
    },
    errorMessage: {
      type: String,
      required: false,
      default: ''
    },
    mask: {
      type: String,
      required: false,
      default: ''
    },
    fnsInput: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      maskTokens
    }
  },
  computed: {
    listeners () {
      return {
        ...this.$listeners,
        input: (event) => {
          // The mask component event already is value
          return ['string', 'number'].includes(typeof event)
            ? this.updateValue(event)
            : this.updateValue(event.target.value)
        },
        blur: (e) => {
          this.updateValue(e.target.value.trim())
          this.$emit('blur', e.target.value.trim())
        }
      }
    },
    attrs () {
      return { ...this.$attrs, class: 'base-form-field__input' }
    }
  },
  methods: {
    updateValue (value) {
      this.$emit('input', value)
    },
    focus () {
      this.$refs.input.$el.focus()
    }
  }
}
</script>

<style scoped lang="scss">
.base-form-field, input {
  &.--error {
    .base-form-field__input {
      border: 1px solid rgba(255, 0, 0, 0.5);
      background: #FF00000D;
      color: #FF4E55;

      &::placeholder, label {
        color: #FF4E55;
      }
    }

    .base-form-field__label {
      color: rgba(255, 0, 0, 0.2);
    }
  }
}

.fns-input {
  padding-top: 11px;
}
input {
  outline: none;
  width: 100%;
  border: 1px solid #C0C6CF;
  border-radius: 8px;
  height: 53px;
  padding-left: 18px;
  font-size: 16px;
  line-height: 19.2px;

  &::placeholder, label {
    font-size: 16px;
    line-height: 19.2px;
    color: var(--bright-text-color);
  }
  &:focus {
    border: 1px solid var(--primary-color);
    box-shadow: 7px 7px 17.5px 0px #1490E940;
    transition: 0.3s;
  }
}
</style>
