<template>
<client-only>
  <div class="user-popup" @click="closePopup">
    <div class="user-popup--container">
      <div class="user-popup--container--content">
        <account-email @confirm="confirm"/>
        <div class="user-popup--container--content__actions">
          <div class="user-popup--container--content__actions__items">
            <div class="user-popup--container--content__actions__items_item"v-if="$store.getters['requisites/getList'].length > 0">
              <input class="user-popup--container--content__actions__items_item__checkbox" type="checkbox" id="user-notify">
              <div class="new-notify"/>
              <img class="user-popup--container--content__actions__items_item__image" src="@/assets/images/notification.svg">

              <label class="user-popup--container--content__actions__items_item_label" for="user-notify" @click="toggleNotification">
                <p class="user-popup--container--content__actions__items_item__text">
                  Уведомления
                  <span class="number-notify">{{ $store.getters['requisites/getList'].length }}</span>
                </p>
                <img class="user-popup--container--content__actions__items_item_label__arrow" :class="{'opened': notification}" src="@/assets/images/small-arrow.svg" v-if="$store.getters['requisites/getList'].length > 0">
              </label>
            </div>
            <template v-if="$store.getters['requisites/getList'].length > 0">
              <div v-if="notification" class="user-popup--container--content__actions__items_box" >
                <AccountNotification
                  v-for="(notification, i) in $store.getters['requisites/getList']"
                  :key="i"
                  :notification="notification"
                />

              </div>
            </template>


            <div class="user-popup--container--content__actions__items_item" @click="goToLink('/paymenthistory')">
              <img class="user-popup--container--content__actions__items_item__image" src="@/assets/images/history.svg">
              <p class="user-popup--container--content__actions__items_item__text">История оплат</p>
            </div>

            <div class="user-popup--container--content__actions__items_item" @click="goToLink('/requisites')" >
              <img class="user-popup--container--content__actions__items_item__image" src="@/assets/images/requisites.svg">
              <p class="user-popup--container--content__actions__items_item__text">Реквизиты для проверки</p>
            </div>

            <div class="user-popup--container--content__actions__items_item" @click="goToLink('/savedCards')">
              <img class="user-popup--container--content__actions__items_item__image" src="@/assets/images/saved.svg">
              <p class="user-popup--container--content__actions__items_item__text">Сохраненные карты</p>
            </div>
            <client-only>
              <div class="user-popup--container--content__actions__items_item" v-if="$webPush.allowed()" @click="openPopupNotification = true">
                <img class="user-popup--container--content__actions__items_item__image" src="@/assets/images/no-notify.svg">
                <p class="user-popup--container--content__actions__items_item__text">Уведомления о задолженностях не подключены</p>
              </div>
            </client-only>


            <div class="user-popup--container--content__actions__items_item" @click="logout" v-if="$store.getters['user/getUser'].email_confirmed">
              <img class="user-popup--container--content__actions__items_item__image" src="@/assets/images/logout.svg">
              <p class="user-popup--container--content__actions__items_item__text">Выйти</p>
            </div>
          </div>
        </div>
      </div>
    </div>
      <popup-notifications v-show="openPopupNotification" @close="openPopupNotification = false" :show-offer="true"/>
  </div>
</client-only>
</template>
<script >
import Input from "~/components/ui/Input.vue";
import AccountEmail from "~/components/account/accountEmail.vue";
import AccountNotification from "~/components/account/accountNotification.vue";
import PopupNotifications from "~/components/popup/popupNotifications.vue";

export default {
  components: {PopupNotifications, AccountEmail, Input, AccountNotification},

  data () {
    return {
      notification: false,
      openPopupNotification: false
    }
  },
  async fetch () {
    await this.$store.dispatch('requisites/getList')
    this.$emit('notificationsCount', this.$store.getters['requisites/getList'].length)
    if (this.$store.getters['requisites/getList'].length) {
      this.isOpenNotifications = true
    }
  },

  computed: {
    webPushId () {
      return this.$webPush.getId()
    }
  },

  methods: {
    closePopup (event) {
      if (event.target.classList[0] === 'user-popup') {
        this.$emit('close')
      }
    },
    goToLink(url) {
      this.$emit('close')
      this.$router.push(url)
    },
    logout () {
      try {
        this.$axios.get(process.env.API_ENDPOINT + 'user/logout').then(() => {
          localStorage.removeItem('storedToken')
          window.location.reload()
          this.isWaitConfirm = true
        })
      } catch (err) {
      }
    },

    async confirm () {
      await this.$fetch()
      await this.$store.dispatch('requisites/getList')
      this.isWaitConfirm = false
    },
    toggleNotification () {
      // @ts-ignore
      this.notification = !this.notification
    }
  },

  mounted() {
    this.$eventBus.$on('WebPushPermit', () => {
      this.openPopupNotification = true
    })
    document.onkeyup = (e) => {
      if (e.code === 'Escape') {
        this.$emit('close')
      }
    }
  }
}

</script>
<style lang="scss">
.user-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 2;
  &--container {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    position: absolute;
    top: 60px;
    right: 120px;
    width: 510px;
    &--content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      &__email {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 20px;
        .email-input{
          display: flex;
          gap: 20px;
        }
        &--waiter {
          &--wait {
            display: inline-flex;
            align-items: flex-start;
            font-size: 18px;
            line-height: 18px;

            &--warning {
              font-size: 14px;
              font-weight: 400;
              color: rgba(255, 78, 85, 1)
            }
            &--email {
              font-family: "Lato", sans-serif;
            }
            &--content {
              margin: 0 8px 0 15px;
              &--edit {
                cursor: pointer;
              }
            }
            &__confirm {
              margin-top: 20px;
              &--text {
                p {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 14px;
                }
              }
            }
          }
        }
        input {
          border-radius: 8px;
          width: 100%;
          padding: 15px 20px;
          border: 1px solid var(--border-color-gray);
          font-size: 14px;
          line-height: 1;
          height: 44px !important;
        }
        button {
          width: 118px;
          height: 44px !important;
          font-size: 14px !important;
          font-weight: 600;
          line-height: 1;
          padding: 10px;
          color: white;
          box-shadow: 7px 7px 17.5px 0px rgba(20, 144, 233, 0.2509803922);
          background-color: var(--primary-color);
          border: none;
          border-radius: 8px;
          text-align: center;
        }
      }
      &__social {
        font-size: 14px;
        line-height: 1;
        color: #13273E60;
        font-weight: 400;
        p {
          margin-top: 7px;
        }
        &__items {
          display: flex;
          justify-content: space-between;
          gap: 15px;
          margin-top: 9px;
          a {
            -webkit-appearance: none;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            padding: 15px 20px;
            max-width: 33.3%;
            width: 33.3%;
            height: 44px;
            margin-top: 0;
            text-align: start;
            border: 1px solid var(--border-color-gray);
            color: rgba(19, 39, 62, 0.6);
            border-radius: 8px;
            p {
              margin-top: 0;
            }
            img {
              margin-right: 15px;

            }
          }
        }
      }
      &__actions {
        &__items {
          display: flex;
          flex-direction: column;
          gap: 20px;
          &_item {
            position: relative;
            cursor: pointer;
            display: flex;
            align-items: center;

            &__image {
              margin-right: 15px;
              height: 30px;
              width: 30px;
            }

            &__text {
              font-size: 16px;
              line-height: 16px;
              color: var(--title-text-color);
              .number-notify {
                font-weight: 600;
                color: #FF4E55;
              }
            }

            &__checkbox {
              display: none;
            }

            &_label {
              cursor: pointer;
              display: flex;
              gap: 5px;
              &__arrow {
                width: 18px;
                height: 18px;

                .opened {
                  transform: rotate(180deg);
                }
              }
            }

            .new-notify {
              position: absolute;
              top: 0;
              left: 22px;
              width: 8px;
              height: 8px;
              background-color: #FF4E55;
              border-radius: 50%;
            }
          }
          &_box {
            display: flex;
            flex-direction: column;
            gap: 10px;
            max-height: 174px;
            overflow-y: auto;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .user-popup {
    &--container {
      width: 100%;
      right: 0;
      &--content {
        gap: 20px;
        &__social {
          &__items {
            max-width: calc(100% - 28px);
          }
          .mailButton {
            max-width: 100% !important;
          }
        }
        &__email {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
