<template>
  <div class="modal-shadow" @mousedown.self="$emit('close')">
    <transition name="drop" appear>
      <div class="modal-frame" :class="{ 'modal-frame--full-size' : isFullSize }">
        <slot v-if="showHeader" name="header">
          <header class="modal-header">
            <h5 class="modal-title">
              {{ title }}
            </h5>
          </header>
        </slot>
        <div class="modal-body row">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    isFullSize: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    document.onkeyup = (e) => {
      if (e.code === 'Escape') {
        this.$emit('close')
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.drop-enter-active {
  transition: transform 200ms linear;
}

.drop-enter-to {
  transform: translateY(0);
}

.drop-enter {
  transform: translateY(-100px);
}

.modal-shadow {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, .5);
  z-index: 1000;
}

.modal-frame {
  // background: white;
  // width: 100%;
  // max-width: 700px;
  // border-radius: 8px;
  // box-shadow: 4px 4px 6px rgba(14, 63, 102, .2);
  // padding: 30px;
  // position: relative;

  // @media (max-width: 767px) {
  //   max-width: 390px;
  // }

  // &--full-size {
  //   max-width: 1200px;
  //   @media (max-width: 1366px) {
  //     max-width: 1000px;
  //   }

  //   @media (max-width: 1200px) {
  //     max-width: 820px;
  //   }

  //   @media (max-width: 992px) {
  //     max-width: 650px;
  //   }

  //   @media (max-width: 767px) {
  //     max-width: 390px;
  //   }
  // }

  .modal-header {
    // padding: 1rem 1rem 1rem 0;
  }

  .close {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // background: none;
    // border: none;
    // font-weight: 400;
    // color: rgba(17, 58, 89, .6);
    // position: absolute;
    // right: 16px;
    // top: 16px;
    // padding: 0;

    &:focus {
      // outline: none;
    }
  }
}

.delete-form {
  .modal-frame {
    // max-width: 402px
  }
}

.modal-title {
  // font-size: 22px;
  // line-height: 127%;
  // border-radius: 16px;
  // text-align: center;
  // margin-bottom: 0;
  // color: black;
  // @media (max-width: 768px) {
  //   font-size: 14px;
  // }
}

.before-leave {
  .modal-title {
    // font-size: 22px;
    // line-height: 127%;
    // background: rgba(237, 30, 36, 0.1);
    // border-radius: 16px;
    // padding: 16px 24px;
    // display: flex;
    // flex-direction: row;
    // text-align: left;
    // align-items: flex-start;
    // gap: 8px;
    // color: #626282;
    // @media (max-width: 768px) {
    //   font-size: 14px;
    // }
  }
}

.appeal-popup {
  .modal-frame {
    // max-height: 90%;
    // overflow-y: scroll;
    // overflow-x: visible;
    // @media (max-width: 768px) {
    //   max-height: 100vh;
    //   height: 100vh;
    // }

    &::-webkit-scrollbar {
      // height: 4px;
      // width: 8px;
      // @media (max-width: 768px) {
      //   display: none;
      // }
      // background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      // background-color: #53535354;
      // border-radius: 8px;
    }
  }
}

.modal-body {
  justify-content: flex-end;
}
</style>
