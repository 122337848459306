export default {
  data() {
    return {
      cardMaskList: require('@pp-frontend/saved-cards/assets/cards-mask.json'),
      cardList: this.cards,
      showDeleteCardPopup: false
    }
  },
  props: {
    cards: {
      type: Array,
      default: () => ([])
    }
  },

  methods: {
    cardBank (card) {
      return this.cardMaskList[+card.card_mask.replace(' ', '').substr(0, 6)] || 'no-bank'
    },

    cardType (card) {
      const paySysPatterns = {
        '^2': 'mir',
        '^4': 'visa',
        '^5[1-5]': 'mastercard',
        '^(50|56|57|58|63|67)': 'maestro'
      }

      for (const sysKey in paySysPatterns) {
        const sysRegex = new RegExp(sysKey)

        if (sysRegex.test(card.card_mask)) {
          return paySysPatterns[sysKey]
        }
      }

      return 'no-type'
    },
    openDeletePaymentPopup (val) {
      this.showDeleteCardPopup = true
      this.$refs.deletePopup.card = val
    },
    async onCardDeleteClick (id) {
      this.$axios.delete(process.env.API_ENDPOINT + 'recurrent/' + id).then(() => {
        const cardIndex = this.cardList.findIndex(c => c.id === id)
        this.cardList.splice(cardIndex, 1)
        this.showDeleteCardPopup = false
      })
    },

    transliterateToRussian(card) {
      const text = this.cardBank(card)
      if (text === 'no-bank') {
        return ''
      }
      if (text === 'alfabank') {
        return 'Альфа банк'
      }
      if (text === 'tinkoff') {
        return 'Т-Банк'
      }
      const transliterationMap = {
        'a': 'а', 'b': 'б', 'v': 'в', 'g': 'г', 'd': 'д', 'e': 'е', 'z': 'з', 'i': 'и',
        'k': 'к', 'l': 'л', 'm': 'м', 'n': 'н', 'o': 'о', 'p': 'п', 'r': 'р', 's': 'с',
        't': 'т', 'u': 'у', 'f': 'ф', 'h': 'х', 'c': 'ц', 'y': 'ы',
        'j': 'й', 'q': 'к',
        'w': 'в',
        'x': 'кс',
      };

      return text.split('').map(char => transliterationMap[char] || char).join('');
    }
  }
}
