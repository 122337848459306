
export default {
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        loadingText: {
            type: String,
            default: 'Проверка'
        },
        text: {
          type: String,
          default: 'Проверить'
        },
    },
}
