<template>
  <div>
    <section class="error-404 center">
      <div class="error-404_body">
        <h1 class="error-404_body__title">Страница не найдена</h1>
        <h2 class="error-404_body__number-error">404</h2>
        <p class="error-404_body__text">Страница была удалена или её никогда не сущестовало, попробуйте поискать её иначе</p>
        <nuxt-link to="/" class="error-404_body__button">На главную</nuxt-link>
      </div>
    </section>
  </div>
</template>

<script>
import headerComp from '~~/components/header/headerComp.vue'
import footerComp from '~/components/footer/footer.vue'
export default  {
  components: {headerComp, footerComp}
}
</script>

<style scoped lang="scss">
.error-404 {
  margin-top: 49px;
  margin-bottom: 74px;

  &_body {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      font-size: 44px;
      font-weight: 700;
      line-height: 52.8px;
      letter-spacing: 0.02em;
      color: var(--title-text-color);
      margin-bottom: 15px;
    }

    &__number-error {
      font-size: 250px;
      font-weight: 700;
      line-height: 250px;
      color: var(--primary-color);
      margin-bottom: 24px;
    }

    &__text {
      font-size: 18px;
      line-height: 32.04px;
      letter-spacing: 0.01em;
      color: var(--title-text-color);
      text-align: center;
      margin-bottom: 40px;
      max-width: 448px;
    }

    &__button {
      width: 100%;
      max-width: 260px;
      height: 53px;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.2px;
      color: white;
      box-shadow: 7px 7px 17.5px 0px #1490E940;
      background-color: var(--primary-color);
      border: none;
      border-radius: 8px;
      padding: 18px 84px;
    }
  }


}

@media (max-width: 767px) {
  .error-404 {
    margin-top: 16px;
    margin-bottom: 24px;

    &_body {
      &__title {
        margin-bottom: 14px;
        font-size: 22px;
        line-height: 26.4px;
      }

      &__number-error {
        margin-bottom: 15px;
        font-size: 175px;
        line-height: 175px;
      }

      &__text {
        margin-bottom: 20px;
        max-width: 390px;
        font-size: 16px;
        font-weight: 400;
        line-height: 28.48px;
      }

      &__button {
        height: 56px;
        line-height: 16px;
        padding: 20px 84px;
      }
    }
  }
}
</style>
