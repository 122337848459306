

import Vue from "vue";
import Component from "vue-class-component";
import account from "~/components/account/account.vue";

@Component({
  components: {account}
})
export default class HeaderComp extends Vue {
  openUserPopup: boolean = false
}
