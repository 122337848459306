export default function ({ route, ssrContext }) {
  const ssrPages = ['/', '/articles'];

  const articleRouteRegex = /^\/articles\/[a-zA-Z0-9-_]+$/;

  if (ssrContext) {
    // Проверка на статические страницы
    if (ssrPages.includes(route.path)) {
      ssrContext.nuxt.serverRendered = true;
    }
    // Проверка на динамические маршруты статей
    else if (articleRouteRegex.test(route.path)) {
      ssrContext.nuxt.serverRendered = true;
    }
    // Для всех остальных страниц выключаем SSR
    else {
      ssrContext.nuxt.serverRendered = false;
    }
  }
}
