<script >

export default {

  data() {
    return {
      timer: 0,
      sbp: ''
    }
  },
  props: {
    sbpUrl: {
      type: String,
      default: ''
    },
    order_id: {
      type: Number,
      default: 0
    }
  },

  watch: {
    order_id(val){
      if (val) {
        this.checkSbpPaymentStatus()
      } else {
        clearTimeout(this._timer)
      }
    }
  },

  methods: {
    checkSbpPaymentStatus() {
      const checkStatus = () => {
        if (this.order_id === 0) {
          return
        }
        window.$nuxt.$axios.get(process.env.API_ENDPOINT + 'fns/order-status', {
          params: {
            order_id: this.order_id
          }
        }).then((response) => {
          if (!response.data.order_payed) {
            this.timer = setTimeout(checkStatus, 5000)
            return
          }

          window.postMessage({msg: 'success_payment'}, '*')
        })
      }
      this.timer = setTimeout(checkStatus, 5000)
    }
  },

  destroyed () {
    clearTimeout(this._timer)
  }
}
</script>

<template>
  <client-only>
    <div class="sbp-tab">
      <div class="sbp-tab--link" >
        <img :src="`https://service.moneta.ru/qrcode.htm?value=${sbpUrl}&w=168&h=168`" width="168" height="168" alt="payment-qr" v-if="sbpUrl">
        <div class="sbp-tab--link__placeholder" v-else></div>
      </div>
      <div class="sbp-tab--how-to">
        <div class="sbp-tab--how-to__title">Как оплатить:</div>
        <div class="sbp-tab--how-to__text">
          <ul>
            <li>Откройте приложение вашего банка</li>
            <li>Отсканируйте QR-код</li>
            <li>Подтвердите платёж</li>
          </ul>
        </div>
      </div>
    </div>
  </client-only>
</template>

<style scoped lang="scss">
.sbp-tab {
  display: flex;
  gap: 20px;
  margin: 25px 0;
  &--link {
    height: 168px;
    width: 168px;
    &__placeholder {
      background-color: #E2E6ED;
      width: 100%;
      height: 100%;
    }
  }
  &--how-to {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 16px;
    &__title {
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
    }
    &__text {
      font-weight: 400;
      font-size: 12px;
      line-height: 13.8px;
      li {
        margin-top: 4px;
      }
    }
  }
}
</style>
