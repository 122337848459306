
import Popup from './../../ui/Popup/Popup.vue'
import SbpTab from "~/components/fineFound/sbpTab.vue";
import {PagePayment} from "@pp-frontend/shared-packages/pages";
import SavedCards from '@pp-frontend/saved-cards/components/SavedCards.vue'
import {Component, Emit, Watch} from 'nuxt-property-decorator'
import PaymentFail from "~/components/fineFound/paymentFail.vue";
import PaymentCardCap from '@pp-frontend/saved-cards/components/PaymentCardCap.vue'
import paymentSbpMixin from "~/mixins/paymentSbpMixin";
import PopupDeleteCard from "~/components/popup/popupDeleteCard.vue";
import savedCardsMixin from "~/mixins/savedCardsMixin";
import PopupSavedCards from "~/components/fineFound/popupSavedCards.vue";
import SearchLoader from "~/components/search/searchLoader.vue";


@Component({
  components: {SearchLoader, PopupSavedCards, PopupDeleteCard, PaymentFail, SbpTab, Popup, SavedCards, PaymentCardCap},
  mixins: [paymentSbpMixin, savedCardsMixin]
})
export default class PopupPayment extends PagePayment {
  /** @member {boolean} showCardCap - показывать ли заглушку карты */
  showCardCap: boolean = false

  text: string = 'Загрузка формы оплаты'
  /** @member {boolean} saveCardFlag - показывать ли checkbox для сохранения текущей карты */
  saveCardFlag: boolean = true
  orderId: number = 0
  /** @member {PaymentCard} activeCard - выбранная к оплате карта */
  activeCard: Object = {
    ps_id: '',
    card_mask: '',
    exp_date: '',
    id: 0
  }
  cards: any = []
  resMoreThanTablet: boolean = false
  @Emit()
  openMobileSbp(){}
  @Watch('data')
  setOrderId(val){
    if(val.order_id) {
      this.orderId = val.order_id
    }
  }

  onSelectCardNextClick (form: any) {
    this.orderId = form.order_id
    if (this.$refs.payFormIframe) {

      // @ts-ignore
      this.activeCard.id = 0

      const loadFrame = () => {
        if (this.$refs.payFormIframe) {
          this.showCardCap = false;
          (this.$refs.payFormIframe as any).removeEventListener('load', loadFrame)
        }
      }

      (this.$refs.payFormIframe as any).src = form.url;
      (this.$refs.payFormIframe as any).addEventListener('load', loadFrame)
    }
  }

  /**
   * сохранять или нет введенную карту
   */
  onSaveChanged () {
    this.saveCardFlag = !this.saveCardFlag
    this.$axios.patch(
      // @ts-ignore
      process.env.API_ENDPOINT + 'recurrent/' + this.data.new_card_attach_id,
      { status_id: this.saveCardFlag ? 'new' : 'disabled' }
    )
  }
  checkRes () {
    return this.resMoreThanTablet = window.innerWidth > 768
  }

  async fetch () {
    this.text = 'Загрузка сохраненных карт…'
    await this.$axios.get(process.env.API_ENDPOINT + 'recurrent').then((response) => {
      if (response.data !== null) {
        this.cards.push(...response.data)

        this.activeCard = this.cards[0]
        this.showCardCap = true
      }
    })
  }

  mounted() {
    this.checkRes()
  }
}
