

import Vue from "vue";
import Component from "vue-class-component";
import BeforeLeavePopup from '@pp-frontend/popups/component/BeforeLeavePopup.vue'
import Popup from "~/ui/Popup/Popup.vue";


@Component({
  computed: {
    Popup() {
      return Popup
    }
  },
  components: {BeforeLeavePopup},
})
export default class PopupCheckFns extends Vue {
  actionClick () {
    // @ts-ignore
    this.$refs.popup.actionClick(true)
  }
}
