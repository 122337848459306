import Vue from "vue";
import {Component, Emit, Prop, Watch} from 'nuxt-property-decorator';

@Component
export default class paymentSbpMixin extends Vue {
  loading: boolean = true
  rubl: string = ''
  cent: string = ''
  showSaveCard: boolean = true
  showSbpQr: boolean = false
  paymentError: boolean = false
  @Emit()
  formFailed() {
  }
  @Prop({
    type: Object,
    default: () => ({})
  }) data
  @Prop({
    type: Number,
    default: 0
  }) orderId
  @Prop({
    type: String,
    default: ''
  }) sbpUrl

  getSum() {
    const sum = this.data.sum.toString().split('.')
    this.rubl = sum[0]
    if (sum.length > 1) {
      if (sum[1].length && sum[1].length === 1) {
        this.cent = sum[1] + '0'
      } else {
        this.cent = sum[1]
      }
    } else {
      this.cent = '0'
    }
  }
  close () {
    this.$emit('close')
    this.loading = true
  }
  onFormFailHandler () {
    this.paymentError = true
    this.loading = true
    this.formFailed()
  }

  onValidCardFilled(data) {
    this.paymentError = false
    this.showSaveCard = false
    window.$nuxt.$axios.get(process.env.API_ENDPOINT + 'user/event', {
      params: {
        event_action: 'valid_card_filled',
        order_id: this.orderId
      }
    })
  }
  onFormSuccessHandler () {
    this.paymentError = false
    this.showSaveCard = true
    this.$router.push({
      name: 'payment-success',
      query: {
        order_id: this.orderId.toString()
      }
    })
  }
  @Watch('sbpUrl')
  setPreparedData (val) {
      if (val) {
        this.loading = false
        this.getSum()
      }
  }
}
