<template>
  <div class="page-top">
    <headerComp/>
    <nuxt class="indent" keep-alive :nuxt-child-key="$route.fullPath"/>
     {{ }}
    <footerComp/>
    <PopupCheckFns class="popup-fns" v-show="$route.name !== 'payment-success'"/>
    <PopupPayment v-if="false"/>
  </div>
</template>
<script >
import headerComp from '~~/components/header/headerComp.vue'
import footerComp from '~/components/footer/footer.vue'
import PopupCheckFns from '~/components/popup/popupCheckFns.vue';
import PopupPayment from '~/components/popup/PopupPayment.vue';
export default  {
  components: {headerComp, footerComp, PopupCheckFns, PopupPayment},
  head () {
    return {
      title: 'Оплатить налоги по ИНН без регистрации, узнать налоговую задолженность',
      meta: [
        { hid: 'og:image', property: 'og:image', content: "https://xn----7sbbmwqeoehd2bb4l.xn--p1ai/logo.png" },
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            "@context": "http://schema.org",
            "@type": "Product",
            "name": "Оплатить налоги и проверить задолженности без регистрации | Оплатить Налог",
            "description": "Проверить налоги по паспорту, ИНН, УИН без регистрации. Быстрая оплата задолженности онлайн. Оплата банковскими картами VISA, MasterCard, МИР и QR-кодом. Гарантия погашения",
            "sku": "Погасить НДФЛ, имущественные налоги, транспортный, земельный, НДС. Оплата пошлин и торговых сборов",
            "brand": {
              "@type": "Organization",
              "name": "ООО «АИР Код»",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Татарстан респ., г Казань, ул Голубятникова, д. 26А",
                "addressLocality": "Казань",
                "postalCode": "420094"
              }
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.8",
              "reviewCount": "15987"
            }
          }
        }
      ],
    }
  },
  mounted() {
    if (this.$fireModule.messaging.isSupported()) {
      this.$fire.messaging.onMessage((e) => {
        const isConfirm = confirm(e.notification.body + ' Посмотреть?')

        if (isConfirm) {
          window.location.href = e.notification.click_action
        }
      })
    }
  }
}
</script>
