<script>

export default {

}
</script>

<template>
<div class="payment-fail">
  <div class="payment-fail--image">
    <img src="~/assets/images/payment-fail.png" height="57" width="64" alt="">
  </div>
  <div class="payment-fail--text">
    Оплата не прошла: на карте недостаточно средств или введены неверные данные
  </div>
</div>
</template>

<style scoped lang="scss">
.payment-fail {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 25px;
  padding: 20px 15px;
  background: #FF4E5520;
  border-radius: 8px;
  &--text {
    font-size: 16px;
    line-height: 19.2px;
    font-weight: 400;
    color: #FF4E55;
  }
}
</style>
