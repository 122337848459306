
export default {
  data () {
    return {
      notificationOpen: false
    }
  },
  props: {
    notification: {
      type: Object,
      default: () => ({})
    }
  },

  methods: {
    toggleNotification () {
      // @ts-ignore
      this.notificationOpen = !this.notificationOpen
    },
    openNotifyLink(url) {
      window.open(url, '_blank')
    }
  },
}
