
import { Component, Prop, Vue } from 'nuxt-property-decorator'

import Popup from "~/ui/Popup/Popup.vue";
@Component({
  components: { Popup },
  fetchOnServer: false
})
export default class extends Vue {
  @Prop() title!: string
  @Prop({
    type: Boolean,
    default: false
  }) showOffer!: boolean

  waitPermission: boolean = false
  isGranted: boolean = false
  showLoader: boolean = false
  browser: string = ''

  async fetch () {
    this.browser = this.$webPush.getBrowser()
    await this.$fire.messagingReady()

    if (!this.showOffer) {
      this.getPermission()
    }
  }

  getPermission () {
    this.waitPermission = true
    this.$webPush.getPermission()
      .then(this.$webPush.saveData)
      .then(() => {
        this.isGranted = true
        this.waitPermission = false
        this.$emit('subscribe')
      })
      .catch(() => {
        this.waitPermission = false
        this.$emit('close')
      })
  }
}

