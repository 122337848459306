
import { Component, Vue, Watch, namespace } from 'nuxt-property-decorator'
import { User } from '@pp-frontend/authorization/types/User'
import Input from "~/components/ui/Input.vue";
import buttonLoader from "~/components/ui/buttonLoader.vue";
const user = namespace('user')
@Component({
  components: {Input, buttonLoader},
  fetchOnServer: false
})
export default class extends Vue {
  isWaitConfirm: boolean = false
  formData: Partial<User> = { email: '' }
  isFetched: boolean = false
  hasEmailError: boolean = false
  isEdit = false
  repeatDisabledTimer: number = 0
  interval!: any
  loading: boolean = false

  async fetch () {
    await this.$store.dispatch('user/getUser')
    this.$eventBus.$emit('UserUpdated')
    this.formData = Object.assign({}, this.$store.getters['user/getUser'])
    this.isEdit = !this.formData.email_confirmed
  }

  get googleOAuthUrl () {
    return process.env.API_ENDPOINT + `user/auth/google?access-token=${localStorage.getItem('storedToken')}&redirect_to=${this.currentPath}`
  }

  get yandexOAuthUrl () {
    return process.env.API_ENDPOINT + `user/auth/yandex?access-token=${localStorage.getItem('storedToken')}&redirect_to=${this.currentPath}`
  }

  get mailOAuthUrl () {
    return process.env.API_ENDPOINT + `user/auth/mail?access-token=${localStorage.getItem('storedToken')}&redirect_to=${this.currentPath}`
  }

  get currentPath () {
    return encodeURIComponent(window.location.origin + this.$route.fullPath)
  }

  get marginStyles () {
    return this.formData.email_confirmed ? '0' : ''
  }

  /**
   * Получаем ссылку и домен на почтовый сервис на основе домена
   *
   * @returns {*} url
   */
  get mailServiceUrl () {
    if (!this.formData.email) {
      return { domain: ''}
    }
    const domain = (this.formData.email as string).split('@')[1]


    switch (domain) {
      case 'gmail.com':
        return {
          url: 'https://mail.google.com/',
          domain: 'gmail.com',
          text: 'Перейти на Gmail.com'
        }
      case 'yandex.ru':
        return {
          url: 'https://mail.yandex.ru/',
          domain: 'yandex.ru',
          text: 'Перейти на Yandex.ru'
        }
      case 'mail.ru':
      case 'list.ru':
      case 'inbox.ru':
      case 'bk.ru':
        return {
          url: 'https://e.mail.ru/',
          domain: 'mail.ru',
          text: 'Перейти на Mail.ru'
        }
      case 'icloud.com':
      case 'mac.com':
      case 'me.com':
        return {
          url: 'https://www.icloud.com/mail/',
          domain: 'icloud.com',
          text: 'Перейти на Icloud.com'
        }
      default:
        return {
          url: `https://${domain}/`,
          domain,
          text: `Перейти на ${domain}`
        }
    }
  }

  @user.State
  user: User

  @Watch('user', { deep: true })
  handleUpdateUser (user: User) {
    this.formData.email = user.email
  }

  @Watch('isWaitConfirm')
  waitChange () {
    if (!this.isWaitConfirm) {
      this.isFetched = false
      clearInterval(this.interval)
    }
  }

  toggleIsEdit() {
    this.isEdit = true
    this.isWaitConfirm = false
  }

  sendEmail () {
    this.validEmail()

    if (this.hasEmailError) {
      return
    }

    this.repeatDisabledTimer = 60
    this.interval = setInterval(() => {
      this.repeatDisabledTimer--

      if (this.repeatDisabledTimer <= 0) {
        clearInterval(this.interval)
      }
    }, 1_000)

    this.hasEmailError = false
    this.loading = true
    this.$axios.get(process.env.API_ENDPOINT + `user/send-email-verification?email=${this.formData.email}&redirect_to=${encodeURIComponent(window.location.href)}`).then((response: any) => {
      if (response.data) {
        this.isEdit = false
        this.isWaitConfirm = true
        if (!this.isFetched) {
          this.fetchConfirm()
        }
        this.loading = false
      }
    }).catch((err: any) => {
      this.hasEmailError = err.response.status === 422
      this.loading = false
    })
  }

  async fetchConfirm () {
    this.isFetched = true
    while (!this.formData.email_confirmed && this.isWaitConfirm) {
      await new Promise((resolve: any) => {
        this.$store.dispatch('user/reloadUser').then(() => {
          this.formData = Object.assign(this.formData, this.$store.getters['user/getUser'])

          if (this.formData.email_confirmed) {
            this.isWaitConfirm = false

            this.$nextTick(() => {
              this.$eventBus.$emit('userConfirm')
              this.$emit('confirm')
            })
          }
          setTimeout(resolve, 5000)
        })
      })
    }
  }

  validEmail () {
    // eslint-disable-next-line
    this.hasEmailError = !/^[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/.test(this.formData.email as string)
  }
}
